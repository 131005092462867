<script lang="ts" setup>
import { useI18n } from 'nuxt-i18n-composable'

import SkrButton from '@/components/button/SkrButton.vue'
import SkrButtonGroup from '@/components/button/SkrButtonGroup.vue'
import OverlayBase from '@/components/overlays/OverlayBase.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'

const emit = defineEmits<{
  (e: 'close'): void
}>()

const { t } = useI18n()
</script>

<template>
  <overlay-base :show="true">
    <v-container class="overlay__body fill-height">
      <v-row align="center">
        <v-col cols="12" md="5" order="2" order-md="1">
          <h1 class="heading__title mb-4">{{ t('sign.error.serialnomismatch.title') }}</h1>
          <div class="mb-6">
            <p class="whitespace-pre-line">{{ t('sign.error.serialnomismatch.text') }}</p>
            <p>
              <i18n path="sign.error.serialnomismatch.help.text">
                <template #question>
                  <strong>{{ t('sign.error.serialnomismatch.help.question') }}</strong>
                </template>
                <template #contactText>
                  <a :href="t('sign.error.serialnomismatch.help.link_url')" target="_blank" rel="noopener">{{
                    t('sign.error.serialnomismatch.help.link_text')
                  }}</a>
                </template>
              </i18n>
            </p>
          </div>
          <skr-button-group size="lg" stacked>
            <skr-button data-cy="checkStatusBtn" :href="t('sign.error.serialnomismatch.cta_url')">{{
              t('sign.error.serialnomismatch.cta')
            }}</skr-button>
            <skr-button type="text" @click="emit('close')">
              {{ t('sign.error.serialnomismatch.back') }}
            </skr-button>
          </skr-button-group>
        </v-col>
        <v-col cols="12" md="7" order="1" order-md="2">
          <responsive-image source="tan-expired" :width="560" :height="511" />
        </v-col>
      </v-row>
    </v-container>
  </overlay-base>
</template>
